export var inHouseModels = [
  {
    id: 'laly',
    images: '/assets/images/team/laly.jpeg',
    name: 'Laly',
    designation: [
      'Height: 170cm',
      'Waist: 23"',
      'Hips: 30"',
      'Bust: 31"',
      'Eyes: grey blue',
      'Hair: dark',
    ],
    instagram: 'https://www.instagram.com/laly.petiteau/'
  },
  {
    id: 'france',
    images: '/assets/images/team/france-mea.jpeg',
    name: 'France mea',
    designation: [
      'Height: 180cm',
      'Waist: 23"',
      'Hips: 31"',
      'Bust: 30"',
      'Hair: black',
      'Eyes: dark brown',
    ],
  },
  {
    id: 'maryna',
    images: '/assets/images/team/maryna.jpeg',
    name: 'Maryna',
    designation: [
      'Height: 180cm',
      'Waist: 24"',
      'Hips: 30"',
      'Bust: 32"',
      'Hair: brown',
      'Eyes: brown',
    ],
  },
  {
    id: 'anastasya',
    images: '/assets/images/team/anastasya.jpeg',
    name: 'Anastasya',
    designation: [
      'Height: 168cm',
      'Waist: 22"',
      'Hips: 34"',
      'Bust: 32"',
      'Hair: brown',
      'Eyes: brown',
    ],
  },
  {
    id: 'leidy',
    images: '/assets/images/team/leidy.jpg',
    name: 'Leidy',
    designation: ['Hair: brown', 'Eyes: brown'],
  },
  {
    id: 'stella',
    images: '/assets/images/team/stella.jpeg',
    name: 'Stella',
    designation: [
      'Height: 180cm',
      'Waist: 24"',
      'Hips: 33"',
      'Bust: 30"',
      'Hair: black',
      'Eyes: brown',
    ],
  },
]