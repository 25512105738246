import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';

import { inHouseModels } from '../../models';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 75,
  height: 90,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

// laly
// france
// maryna
// anastasya
// leidy
// stella

const modelsArr = [
  ...inHouseModels,
  {
    id: 'not-applicable',
    name: 'Not applicable',
  },
];

function EnquiryForm() {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState('');
  const [number, setNumber] = useState('');
  const [models, setModels] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const search = location.search;
    const modelName = new URLSearchParams(search).get('model');

    for (var i = 0; i < modelsArr.length; i++) {
      if (modelsArr[i].id === modelName) {
        setModels([modelsArr[i]]);
      }
    }
  }, [location]);

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      if (k === 'models') {
        const modelNames = data[k].map((e) => e.name).join(', ');
        formData.append(k, modelNames);
      } else {
        formData.append(k, data[k]);
      }
    });
    return formData;
  };

  const handleSubmit = (e) => {
    const data = {
      'form-name': 'enquiry',
      name,
      email,
      message,
      country,
      number,
      models,
    };

    fetch('/', {
      method: 'POST',
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data),
    })
      .then(() => {
        setStatus('Thank you for reaching out to us. We will contact you as soon as possible!');
        setName('');
        setEmail('');
        setMessage('');
        setCountry('');
        setNumber('');
        setModels([]);
      })
      .catch((error) => setStatus('Form Submission Failed!'));

    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      return setName(value);
    }
    if (name === 'email') {
      return setEmail(value);
    }
    if (name === 'message') {
      return setMessage(value);
    }
    if (name === 'country') {
      return setCountry(value);
    }
    if (name === 'number') {
      return setNumber(value);
    }
  };

  const handleSelect = (newModels) => {
    return setModels(newModels);
  };

  const thumbs = () => {
    var found = false;
    for (var i = 0; i < models.length; i++) {
      console.log(models);
      if (models[i].id === 'not-applicable') {
        found = true;
      }
    }

    if (!found) {
      return models.map((file) => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img src={file.images} style={img} alt={file.name} />
          </div>
        </div>
      ));
    }
  };

  return (
    <div className='contact-form--1'>
      <div className='container'>
        <div className='row row--35 align-items-start'>
          <div className='col-lg-12 order-2 order-lg-1'>
            <div className='section-title text-left mb--50'>
              <h2 className='title'>Contact Us.</h2>
              <p className='description'>Make an enquiry for one of our beautiful models.</p>
            </div>
            <div className='form-wrapper'>
              <form onSubmit={handleSubmit} action='/thank-you/'>
                <div className='container'>
                  <div className='row row--35 align-items-start'>
                    <div className='col-lg-6 order-1 order-lg-1'>
                      <p>
                        <label>
                          Name *
                          <input
                            type='text'
                            name='name'
                            value={name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Email *
                          <input
                            type='email'
                            name='email'
                            value={email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Country *
                          <input
                            type='text'
                            name='country'
                            value={country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Telephone Number *
                          <input
                            type='text'
                            name='number'
                            value={number}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                    </div>
                    <div className='col-lg-6 order-2 order-lg-2'>
                      <div className='models-section'>
                        <label>
                          Models *
                          <Multiselect
                            options={modelsArr}
                            className='multi-select'
                            name='models'
                            required
                            displayValue='name'
                            onSelect={handleSelect}
                            onRemove={handleSelect}
                            selectedValues={models}
                          />
                        </label>
                        <aside style={thumbsContainer}>{thumbs()}</aside>
                      </div>
                      <p>
                        <label>
                          Details about your enquiry *
                          <textarea
                            name='message'
                            value={message}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                    </div>
                  </div>
                </div>

                <p>
                  <button className='rn-button-style--2 btn-solid' type='submit'>
                    Submit
                  </button>
                </p>
                <p>Fields marked with * are required to submit the form</p>
              </form>
              <h3>{status}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryForm;
