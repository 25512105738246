import React, { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function GetScouted() {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [dob, setDob] = useState('');
  const [country, setCountry] = useState('');
  const [number, setNumber] = useState('');
  const [instagram, setInstagram] = useState('');
  const [height, setHeight] = useState('');
  const [hair, setHair] = useState('');
  const [eye, setEye] = useState('');
  const [file, setFile] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setFile(acceptedFiles[0]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
    acceptedFiles,
  } = useDropzone({
    onDrop,
  });

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleSubmit = (e) => {
    const data = {
      'form-name': 'get-scouted',
      name,
      email,
      message,
      dob,
      country,
      number,
      instagram,
      height,
      hair,
      eye,
      file,
    };

    fetch('/', {
      method: 'POST',
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data),
    })
      .then(() => {
        setStatus(
          'Thank you for submitting your details. We will contact you as soon as possible!'
        );
        setName('');
        setEmail('');
        setMessage('');
        setDob('');
        setCountry('');
        setNumber('');
        setInstagram('');
        setHeight('');
        setHair('');
        setEye('');
        setFile({});
      })
      .catch((error) => setStatus('Form Submission Failed!'));

    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      return setName(value);
    }
    if (name === 'email') {
      return setEmail(value);
    }
    if (name === 'message') {
      return setMessage(value);
    }
    if (name === 'dob') {
      return setDob(value);
    }
    if (name === 'country') {
      return setCountry(value);
    }
    if (name === 'number') {
      return setNumber(value);
    }
    if (name === 'instagram') {
      return setInstagram(value);
    }
    if (name === 'height') {
      return setHeight(value);
    }
    if (name === 'hair') {
      return setHair(value);
    }
    if (name === 'eye') {
      return setEye(value);
    }
  };

  const files = acceptedFiles.map((f) => <li key={f.name}>{f.name}</li>);
  return (
    <div className='contact-form--1'>
      <div className='container'>
        <div className='row row--35 align-items-start'>
          <div className='col-lg-12 order-2 order-lg-1'>
            <div className='section-title text-left mb--50'>
              <h2 className='title'>Contact Us.</h2>
              <p className='description'>
                Get scouted by a mother agency that will help you create the career of dreams.
              </p>
            </div>
            <div className='form-wrapper'>
              <form onSubmit={handleSubmit} action='/thank-you/'>
                <div className='container'>
                  <div className='row row--35 align-items-start'>
                    <div className='col-lg-6 order-1 order-lg-1'>
                      <p>
                        <label>
                          Name *
                          <input
                            type='text'
                            name='name'
                            value={name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Email *
                          <input
                            type='email'
                            name='email'
                            value={email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Date Of Birth *
                          <input
                            type='text'
                            name='dob'
                            value={dob}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Country *
                          <input
                            type='text'
                            name='country'
                            value={country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Telephone Number *
                          <input
                            type='text'
                            name='number'
                            value={number}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Instagram
                          <input
                            type='text'
                            name='instagram'
                            value={instagram}
                            onChange={handleChange}
                          />
                        </label>
                      </p>
                    </div>
                    <div className='col-lg-6 order-2 order-lg-2'>
                      <p>
                        <label>
                          Height *
                          <input
                            type='text'
                            name='height'
                            value={height}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Hair Color *
                          <input
                            type='text'
                            name='hair'
                            value={hair}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          Eye Color *
                          <input
                            type='text'
                            name='eye'
                            value={eye}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>
                      <p>
                        <label>
                          A few words about you and why you want to be a model *
                          <textarea
                            name='message'
                            value={message}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </p>

                      <section className='container'>
                        <div {...getRootProps({ style })}>
                          <input required {...getInputProps()} />
                          <p>Drag 'n' drop some files pictures of you here *</p>
                          <p>
                            We would like to see: a headshot, a profile, a mid-length and a
                            full-length picture
                          </p>
                        </div>
                        {files.length > 0 ? (
                          <aside>
                            <h4>Files</h4>
                            <ul>{files}</ul>
                          </aside>
                        ) : (
                          ''
                        )}
                      </section>
                    </div>
                  </div>
                </div>

                <p>
                  <button
                    className='rn-button-style--2 btn-solid'
                    type='submit'
                    id='mc-embedded-subscribe'
                  >
                    Submit
                  </button>
                </p>
                <p>Fields marked with * are required to submit the form</p>
              </form>
              <h3>{status}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetScouted;
