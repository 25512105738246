import React, { Component } from 'react';
// import PageHelmet from "../component/common/Helmet";
import { FiMail, FiMapPin } from 'react-icons/fi';
import EnquiryForm from './contact/EnquiryForm';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/Header';
import FooterTwo from '../component/footer/FooterTwo';
import Helmet from '../component/common/Helmet';
import Breadcrumb from '../elements/common/Breadcrumb';

class Enquiry extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet pageTitle='Make an enquiry' />
        {/* Start Header Area  */}
        <Header headertransparent='header--transparent' colorblack='color--black' logo='light' />
        {/* End Header Area  */}

        {/* Start Breadcrump Area */}
        <Breadcrumb title={'Make an enquiry'} />
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className='rn-contact-page ptb--120 bg_color--1'>
          <EnquiryForm />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Top Area  */}
        <div className='rn-contact-top-area ptb--120 bg_color--5'>
          <div className='container'>
            <div className='row'>
              {/* Start Single Address  */}
              <div className='col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMail />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Email Address</h4>
                    <p>
                      <a href='mailto:info@sjem.co.uk'>info@sjem.co.uk</a>
                    </p>
                    <p>
                      <a href='mailto:stephane.moreau@sjem.co.uk'>stephane.moreau@sjem.co.uk</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className='col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMapPin />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Location</h4>
                    <p>71-75 Shelton Street, Covent Garden</p>
                    <p>WC2H 9JQ, London, ENGLAND</p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
      </React.Fragment>
    );
  }
}
export default Enquiry;
