import React from 'react';
import { Parallax } from 'react-parallax';
import { Link } from 'react-router-dom';

const image1 = '/assets/images/home/background-model.jpg';

const SlideList = [
  {
    textPosition: 'text-center',
    category: '',
    description: 'Scouting, developing and managing models across the globe',
    buttonText: 'Get scouted',
    buttonLink: '/get-scouted',
  },
];

const ParallaxElement = (props) => (
  <div className='slider-activation slider-creative-agency'>
    <Parallax bgImage={image1} style={{ width: '100%' }} strength={1000}>
      {SlideList.map((value, index) => (
        <div
          className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'
          key={index}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className={`inner ${value.textPosition}`}>
                  {value.category ? <span>{value.category}</span> : ''}
                  {props.title ? <h1 className='title theme-gradient'>{props.title}</h1> : ''}
                  {value.description ? <p className='description'>{value.description}</p> : ''}
                  {value.buttonText ? (
                    <div className='slide-btn'>
                      <Link className='rn-button-style--2 btn-primary-color' to={value.buttonLink}>
                        {value.buttonText}
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                  <a href='mailto:info@sjem.co.uk'>info@sjem.co.uk</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Parallax>
  </div>
);

export default ParallaxElement;
