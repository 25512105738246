import React, { Component } from 'react';
import { FaInstagram, FaCalendarCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { inHouseModels } from '../models';

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {inHouseModels.map((value, i) => (
          <div className={`${column}`} key={i}>
            <div className='team'>
              <div className='thumbnail'>
                <img src={value.images} alt={value.title} />
              </div>
              <div className='content'>
                <h4 className='title'>{value.name}</h4>
                {value.designation
                  ? value.designation.map((val, i) => (
                      <p key={i} className='designation'>
                        {val}
                      </p>
                    ))
                  : ''}
              </div>
              <ul className='social-icon'>
                {value.instagram ? (
                  <li>
                    <a href={`${value.instagram}`} target='_blank' rel='noopener noreferrer'>
                      <FaInstagram />
                    </a>
                  </li>
                ) : (
                  ''
                )}
                <li>
                  <Link to={`/enquiry?model=${value.id}`} model={value}>
                    <FaCalendarCheck />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
