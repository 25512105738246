import React, { Component, Fragment } from 'react';
// import Slider from "react-slick";
// import { slickDot } from "../page-demo/script";

import Header from '../component/header/Header';
// import ServiceList from "../elements/service/ServiceList";
// import CounterOne from "../elements/counters/CounterOne";
// import Testimonial from "../elements/Testimonial";
import Team from '../elements/Team';
// import BlogContent from "../elements/blog/BlogContent";
// import BrandTwo from "../elements/BrandTwo";
import FooterTwo from '../component/footer/FooterTwo';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Helmet from '../component/common/Helmet';
import ParallaxElement from '../elements/ParallaxElement';

class CreativeAgency extends Component {
  render() {
    // const PostList = BlogContent.slice(0 , 5);
    return (
      <Fragment>
        <Helmet pageTitle='Home' />
        <Header logo='light' />
        {/* Start Slider Area   */}
        <ParallaxElement title='SJEM Models' />
        {/* End Slider Area   */}

        {/* Start Models Area  */}
        <div className='rn-team-area ptb--120 bg_color--5'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='section-title service-style--3 text-left mb--25 mb_sm--0'>
                  <h2 className='title'>Our models</h2>
                </div>
              </div>
            </div>
            <div className='row'>
              <Team column='col-lg-4 col-md-6 col-sm-6 col-12' />
            </div>
          </div>
        </div>
        {/* End Models Area  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}
        {/* Start Back To Top */}
        <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CreativeAgency;
